import React, { useEffect }  from "react";
import ExtendedImageComponent from "@stories/elements/ExtendedImage/ExtendedImage";
import ExtendedVideo from "@src/stories/elements/ExtendedVideo/ExtendedVideo";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import "photoswipe/style.css";

const lightbox = new PhotoSwipeLightbox({
  gallery: "#swipe-gallery",
  children: "a",
  pswpModule: () => import("photoswipe")
});

export const CarouselImage = ({imageUrl, alt, isLazy}) => {  
 const isMobile = useMobileBreakpoint('1024px');
 return (
    <div className="pswp-gallery" id="swipe-gallery">
        <a  
            data-pswp-src={`${imageUrl}`}
            data-pswp-width={isMobile ? "1669" :  "2500" }
            data-pswp-height={isMobile ? "2500" : "1669"  }
            target="_blank">
            <img
                src={imageUrl}
                alt={alt}
                loading={isLazy ? 'lazy' : undefined}
            />
        </a>
    </div>
)}


export const ExtendedProductGalleryZoom = ({ modal_id, isOpen, onClose , images, currentIndex }) => {
    if (!isOpen) return null;

    useEffect(()=> {
        lightbox.init();
    },[])

    return (
        <div id={`${modal_id}`} className={`fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-90 overflow-y-auto w-full h-full z-50`} style={{backdropFilter: "blur(6px)"}}>
            <div className={`pb-0 lg:py-[62px] px-0 lg:px-[32px] w-full h-full flex`}>
                    <div className={`image-carousel image-carousel-zoom`} 
                             {
                                ...{
                                    'x-data': "extended_carousel",
                                    'x-init': `$nextTick(() => { initialise(false, true, false, false, false, "${modal_id}", ${currentIndex}) })`
                                }
                                }
                    >
                            <div className="carousel-images carousel-images-zoom carousel w-full h-auto relative" x-ref="carousel"
                                >
                                {images.map((image, index) => (
                                    <div key={index} className={`w-full h-full flex items-center justify-center ${index == 1 ? 'is-initial-select' : ''}`}>
                                        { image.videoSrc &&                                     
                                            <ExtendedVideo
                                                videoUrl={image.videoSrc?.url} 
                                                autoplay={true}
                                            />
                                        }
                                        {
                                          !image.videoSrc &&   <CarouselImage imageUrl={image.url} alt={image.alt} isLazy={true}/>
                                        }
                                        
                                        <div className="carousel-close" onClick={() => onClose(true)}> 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M16.192 6.34375L11.949 10.5858L7.70697 6.34375L6.29297 7.75775L10.535 11.9998L6.29297 16.2418L7.70697 17.6558L11.949 13.4137L16.192 17.6558L17.606 16.2418L13.364 11.9998L17.606 7.75775L16.192 6.34375Z" fill="white"/>
                                            </svg>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
            </div>
        </div>
    )
}

ExtendedProductGalleryZoom.propTypes = {};
