import helpers from "./helpers";
import api from "./api";

export default {
  getProductQuery() {
    return `
      id
      title
      handle
      tags
      productType
      createdAt
      totalInventory
      vendor
      options {
        name,
        values
      }
      featuredImage {
        altText
        url
      }
      isGiftCard
      images(first: 2) {
        edges {
          node {
            altText
            url
          }
        }
      }
      priceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      chilled: metafield(namespace: "custom", key: "chilled") {
        id
        namespace
        key
        value
      }
      deliveryDate: metafield(namespace: "custom", key: "pre_order_delivery_date") {
        id
        namespace
        key
        value
      }
      deliveryDateEnd: metafield(namespace: "custom", key: "pre_order_end_delivery_date") {
        id
        namespace
        key
        value
      }
      variants(first: 100) {
        edges {
          node {
            id
            availableForSale
            selectedOptions {
              value
            }
            compareAtPrice {
              amount
            }
            image {
              altText
              url
            }
          }
        }
      }
    `;
  },
  getRelatedProductsQuery(tags) {
    const tagQuery = tags.map((tag) => `tag:'${tag}'`).join(" OR ");
    return `
    query getRelatedProducts {
      products(first: 10, query: "(${tagQuery})") {
        edges {
          node {
            id
            title
            handle
            images(first: 10) {
              edges {
                node {
                  altText
                  src
                }
              }
            }
          }
        }
      }
    }
  `;
  },
  // Helper function to generate the GraphQL query
  getRelatedProductsByHandleQuery(handles) {
    const handlesString = handles.map((handle) => `'${handle}'`).join(" OR ");
    return `
        {
            products(first: 20, query: "(${handlesString})") {
                edges {
                    node {
                        id
                        title
                        handle
                        tags

                        priceRange {
                            maxVariantPrice {
                              amount
                            }
                            minVariantPrice {
                              amount
                            }
                        }
                        variants(first: 100) {
                            edges {
                              node {
                                id
                                availableForSale
                                selectedOptions {
                                  value
                                }
                                compareAtPrice {
                                  amount
                                }
                                image {
                                  altText
                                  url
                                }
                              }
                            }
                          }
                        images(first: 10) {
                            edges {
                                node {
                                    src
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
  },
  async authenticatedFetch(fetchDetails) {
    fetchDetails.url = `${helpers.getDomain()}/admin/api/2024-01/graphql.json`;
    fetchDetails.shop_url = `${helpers.getDomain(false, true)}/admin/api/2024-01/graphql.json`;
    // fetchDetails.key = `${api.getAdminToken()}`;
    fetchDetails.key = `SHOPIFY_API_AUTHENTICATED_KEY`;
    // fetchDetails.hash = sig;

    const response = await fetch(`${helpers.getDomain()}/apps/arctheme/fetch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fetchDetails),
    });

    if (!response.ok) {
      console.error(`Error calling authenticated api: ${response.statusText}`);
    }

    console.log(response);
    return await response.json();
  },
    async authenticatedRestFetch(fetchDetails, endpoint) {
    fetchDetails.url = `${helpers.getDomain()}/admin/api/2024-07/${endpoint}.json`;
    fetchDetails.shop_url = `${helpers.getDomain(false,true)}/admin/api/2024-07/${endpoint}.json`;
    // fetchDetails.key = `${api.getAdminToken()}`;
    fetchDetails.key = `SHOPIFY_API_AUTHENTICATED_KEY`;
    // fetchDetails.hash = sig;

    const response = await fetch(`${helpers.getDomain()}/apps/arctheme/fetch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fetchDetails),
    });

    if (!response.ok) {
      console.error(`Error calling authenticated api: ${response.statusText}`);
    }

    console.log(response);
    return await response.json();
  },
  async unauthenticatedFetch(query, variables = null, storefront = false) {
    // Construct the URL based on the 'storefront' flag
    const apiPath = storefront ? "api" : "admin/api";
    const url = `${helpers.getDomain()}/${apiPath}/2024-07/graphql.json`;

    // Construct headers conditionally based on the 'storefront' flag
    let headers = {
      "Content-Type": "application/graphql",
    };

    if (storefront) {
      headers["X-Shopify-Storefront-Access-Token"] =
        `${api.getStorefrontToken()}`; // This key to be stored elsewhere
    } else {
      headers["Content-Type"] = "application/json";
      headers["X-Shopify-Access-Token"] = `${api.getPublicAccessToken()}`; // This key to be stored elsewhere (Can only be private app key, cannot be the public/custom app key)
    }

    // Perform the GraphQL request
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: query,
    });

    return await response.json();
  },

    async unauthenticatedRestFetch(endpoint, payload = null) {
        // Construct the URL based on the 'storefront' flag
        const apiPath = "admin/api";
        const url = `${helpers.getDomain()}/${apiPath}/2024-07/${endpoint}.json`;

        // Construct headers conditionally based on the 'storefront' flag
        let headers = {
            "Content-Type": "application/json",
        };
        headers["Content-Type"] = "application/json";
        headers["X-Shopify-Access-Token"] = `${api.getPublicAccessToken()}`; // This key to be stored elsewhere (Can only be private app key, cannot be the public/custom app key)

        // Perform the GraphQL request
        const response = await fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload),
        });

        return await response.json();
    },
  async requestStorefront(query) {
    const url = `${helpers.getDomain()}/api/2024-01/graphql.json`;
    const headers = {
      "Content-Type": "application/graphql",
      "X-Shopify-Storefront-Access-Token": `${api.getStorefrontToken()}`,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: query,
    });

    return await response.json();
  },
  async getStockForProducts(productData, storefront = false) {
    const data = `{
            nodes(ids: [${productData.reduce((filtered, id) => {
              filtered.push('"' + "gid://shopify/Product/" + id + '"');
              return filtered;
            }, [])}]) {
              id
              ... on Product {
                ${this.getProductQuery()}
              }
            }
          }`;

    // Construct headers conditionally based on the 'storefront' flag
    let headers = {
      "Content-Type": "application/graphql",
    };

    if (storefront) {
      headers["X-Shopify-Storefront-Access-Token"] =
        `${api.getStorefrontToken()}`; // This key to be stored elsewhere
    } else {
      headers["X-Shopify-Access-Token"] = `${api.getPublicAccessToken()}`; // This key to be stored elsewhere (Can only be private app key, cannot be the public/custom app key)
    }

    let transformedProducts = null;
    const response = await fetch(
      `${helpers.getDomain()}/api/2024-01/graphql.json`,
      {
        method: "POST",
        headers: headers,
        body: data,
      },
    );
    const jsonResponse = await response.json();
  },
  async getShopifyProductList(productData) {
    const data = `{
          nodes(ids: [${productData.reduce((filtered, id) => {
            filtered.push('"' + "gid://shopify/Product/" + id + '"');
            return filtered;
          }, [])}]) {
            id
            ... on Product {
              ${this.getProductQuery()}
            }
          }
        }`;

    // Construct headers conditionally based on the 'storefront' flag
    let headers = {
      "Content-Type": "application/graphql",
    };
    headers["X-Shopify-Storefront-Access-Token"] =
      `${api.getStorefrontToken()}`; // This key to be stored elsewhere
    headers["X-Shopify-Access-Token"] = `${api.getPublicAccessToken()}`; // This key to be stored elsewhere (Can only be private app key, cannot be the public/custom app key)

    let transformedProducts = null;
    const response = await fetch(
      `${helpers.getDomain()}/api/2024-01/graphql.json`,
      {
        method: "POST",
        headers: headers,
        body: data,
      },
    );

    const jsonResponse = await response.json();
    const products = jsonResponse.data?.nodes.filter((node) => node);
    transformedProducts = products?.map((product) =>
      this.transformGraphqlProduct(product),
    );

    return transformedProducts;
  },
  async getRelatedProducts(products, checkTag = "colour:") {
    const tags = products.flatMap((product) =>
      product.tags.filter((tag) => tag.startsWith(checkTag)),
    );
    const uniqueTags = [...new Set(tags)];
    const relatedProductsQuery = this.getRelatedProductsQuery(uniqueTags);

    let headers = {
      "Content-Type": "application/graphql",
      "X-Shopify-Storefront-Access-Token": `${api.getStorefrontToken()}`,
      "X-Shopify-Access-Token": `${api.getPublicAccessToken()}`,
    };

    const response = await fetch(
      `${helpers.getDomain()}/api/2024-01/graphql.json`,
      {
        method: "POST",
        headers: headers,
        body: relatedProductsQuery,
      },
    );

    const jsonResponse = await response.json();

    const relatedProducts = jsonResponse.data?.products?.edges.map(
      (edge) => edge.node,
    );

    return relatedProducts || [];
  },
  async getRelatedProductsByTag(product, tagPrefix = "colour:") {
    // Extract handles from tags that start with the given prefix
    const tags = product.tags
      .filter((tag) => tag.startsWith(tagPrefix))
      .map((tag) => tag.replace(tagPrefix, "").trim());

    const uniqueTags = [...new Set(tags)]; // Get unique handles

    const relatedProductsQuery =
      this.getRelatedProductsByHandleQuery(uniqueTags);

    let headers = {
      "Content-Type": "application/graphql",
      "X-Shopify-Storefront-Access-Token": `${api.getStorefrontToken()}`,
      "X-Shopify-Access-Token": `${api.getPublicAccessToken()}`,
    };

    const response = await fetch(
      `${helpers.getDomain()}/api/2024-01/graphql.json`,
      {
        method: "POST",
        headers: headers,
        body: relatedProductsQuery,
      },
    );

    const jsonResponse = await response.json();

    const relatedProducts = jsonResponse.data?.products?.edges.map(
      (edge) => edge.node,
    );
    const transformedRelatedProducts = relatedProducts?.map((product) =>
      this.transformGraphqlProduct(product),
    );
    return transformedRelatedProducts || [];
  },
  async getRecommendedProducts(productId) {
    try {
      let headers = {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": `${api.getStorefrontToken()}`,
      };

      const response = await fetch(
        `${helpers.getDomain()}/recommendations/products.json?product_id=${productId}`,
        {
          method: "GET",
          headers: headers,
        },
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching recommendations: ${response.statusText}`,
        );
      }

      const jsonResponse = await response.json();

      return jsonResponse.products || [];
    } catch (error) {
      console.error("Failed to fetch product recommendations:", error);
    }
  },
  transformPrice(price) {
    return price / 100;
  },
  transformGraphqlProduct(productNode, collectionHandle = null) {
    const hasOnlyDefaultVariant =
      (productNode?.options?.[0]?.values?.[0] ?? "") === "Default Title";

    return productNode
      ? {
          objectType: "product",
          productType: productNode.productType,
          isGiftCard: productNode.isGiftCard,
          handle: productNode.handle,
          url: collectionHandle
            ? `/collections/${collectionHandle}/products/${productNode.handle}`
            : `/products/${productNode.handle}`,
          id: parseInt(productNode.id.replace("gid://shopify/Product/", "")),
          tags: productNode.tags.map((tag) => tag.toLowerCase()),
          title: productNode.title,
          createdAt: productNode.createdAt,
          totalInventory: productNode.totalInventory,
          vendor: productNode.vendor,
          chilled: productNode.chilled?.value,
          deliveryDate: productNode.deliveryDate?.value,
          deliveryDateEnd: productNode.deliveryDateEnd?.value,
          // metafield values are transformed into arrays for consistency
          metafields: productNode.metafields
            ?.filter((node) => node)
            .map((metafield) => {
              return {
                ...metafield,
                value: metafield.value.includes("[")
                  ? JSON.parse(metafield.value).flat()
                  : [metafield.value],
              };
            }),
          variants: productNode.variants?.edges.map(({ node }) => {
            return {
              id: parseInt(
                node.id.replace("gid://shopify/ProductVariant/", ""),
              ),
              available: node.availableForSale,
              compare_at_price: node.compareAtPrice?.amount * 100 || null,
              options: node.selectedOptions.map((option) => option.value),
              image: {
                alt: node?.image?.altText
                  ? node.image.altText
                  : productNode.title,
                media_type: "image",
                src: node?.image?.url ? node.image.url : null,
              },
            };
          }),
          price_calculated: productNode.priceRange?.minVariantPrice.amount,
          compare_at_price_calculated:
            productNode.variants?.edges[0].node.compareAtPrice?.amount || null,
          price: productNode.priceRange?.minVariantPrice.amount * 100,
          compare_at_price:
            productNode.variants?.edges[0].node.compareAtPrice?.amount * 100 ||
            null,
          featuredImage: {
            alt: productNode?.featuredImage?.altText
              ? productNode.featuredImage.altText
              : productNode.title,
            media_type: "image",
            src: productNode?.featuredImage?.url
              ? productNode.featuredImage.url
              : null,
          },
          media: productNode.images?.edges.map((image) => {
            return {
              alt: image?.node?.altText
                ? image.node.altText
                : productNode.title,
              media_type: "image",
              src: image?.node?.url ? image.node.url : null,
            };
          }),

          has_only_default_variant: hasOnlyDefaultVariant,
          options: productNode.options?.map((option) => option.name),
          options_with_values: productNode.options?.map((option) => {
            return {
              name: option.name,
              position: productNode.options.indexOf(option) + 1,
              values: option.values,
            };
          }),
        }
      : null;
  },

  getShopifyImageUrl(
    originalUrl,
    width = null,
    height = null,
    customQueries = null,
  ) {
    const params = {
      width: width ? Math.ceil(width) : null,
      height: height ? Math.ceil(height) : null,
      ...customQueries,
    };

    const url = helpers.updateUrlParameters(originalUrl, params);
    return url;
  },
};
