import React, { ReactNode, useState, forwardRef, useImperativeHandle } from "react";
import { clsx } from 'clsx';

const Collapsible = forwardRef((props, ref) => {
  const {
    title,
    active = false,
    openIcon = 'icon-at-plus',
    closeIcon = 'icon-at-minus',
    children,
    disabled = false,
    desktopDisabled = false,
    nested = false,
    tier = null
  } = props;

  const [isActive, setIsActive] = useState(active);

  function toggle() {
    if (disabled) return;
    setIsActive((prev) => !prev);
  }

  useImperativeHandle(ref, () => ({
    toggle,
  }));

  return (
    <div
      className={clsx({
        'collapsible': true,
        'collapsible--active-nested-1': tier === 1 && isActive,
        'collapsible--disabled-nested-1': tier === 1 && disabled,
        'collapsible--active-nested-2': tier === 2 && isActive,
        'collapsible--disabled-nested-2': tier === 2 && disabled,
        'collapsible--active': !tier && isActive,
        'collapsible--disabled': !tier && disabled,
        'collapsible--desktop-disabled': desktopDisabled,
      })}
    >
      <div
        className={`collapsible__title ${tier ? 'collapsible__title-nested' : ''}`}
        onClick={toggle}
      >
        {title}

        {!disabled && !tier && (
          <>
            <span className={`collapsible__expand-icon ${openIcon}`}></span>
            <span className={`collapsible__collapse-icon ${closeIcon}`}></span>
          </>
        )}
        {!disabled && tier === 1 && (
          <>
            <span className={`collapsible__expand-icon-nested-1 ${openIcon}`}></span>
            <span className={`collapsible__collapse-icon-nested-1 ${closeIcon}`}></span>
          </>
        )}
        {!disabled && tier === 2 && (
          <>
            <span className={`collapsible__expand-icon-nested-2 ${openIcon}`}></span>
            <span className={`collapsible__collapse-icon-nested-2 ${closeIcon}`}></span>
          </>
        )}
      </div>

      <div className={clsx({
        'collapsible__content': !tier,
        'collapsible__content-nested-1': tier === 1,
        'collapsible__content-nested-2': tier === 2,
      })}>
        {children}
      </div>
    </div>
  );
});


export default Collapsible;