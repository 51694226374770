import shopifyHelper from "../../shopify";
import api from "../../api";

export const wishlist = {
  name: "wishlist",
  store() {
    return {
      response: null,
      lists: Alpine?.$persist([]).as("wishlist_lists"), // Persist multiple lists, not just products
      loggedIn: false,
      init() {
        window.addEventListener("requestWishlistProducts", (event) => {
          this.sendWishlistProducts(event.detail.type);
        });
        window.addEventListener('add-wishlist', this.addToWishlist.bind(this));
        window.addEventListener('remove-wishlist', this.removeFromWishlist.bind(this));
        window.addEventListener('check-wishlist', this.isItemInWishlist.bind(this));
      },

      sendWishlistProducts(listType) {
        const list = this.lists.find((list) => list.type === listType);
        if (list) {
        const event = new CustomEvent("responseWishlistProducts", {
          detail: {
            message: "Wishlist products returned",
            items: list.items, // Return all wishlist lists here
            listType
          },
        });
        window.dispatchEvent(event);
      }
      },

      // Generic method to extract product IDs
      extractProductIds(responseData, listType = "products") {
        let fields = responseData.data.metaobject.fields;
        let itemIds = [];

        fields.forEach((field) => {
          if (field.key === listType) {
            let items = JSON.parse(field.value);
            items.forEach((item) => {
              let matches = item.match(/Product\/(\d+)/); // Adjust this for other types like recipes
              if (matches) {
                itemIds.push(matches[1]);
              }
            });
          }
        });

        return itemIds;
      },

      // Check if item exists in a specific list
      isItemInWishlist(event) {
        const { detail:{ listType, itemId }} = event;
    
        const list = this.lists.find((list) => list.type === listType);
        if (!list) return false;

        const doesExist = list.items.some(
          (item) => parseInt(item.id) == parseInt(itemId),
        );

        const dispatchEvent = new CustomEvent("check-wishlist-response", {
          detail: {
            exist: doesExist,
            itemId: itemId
          },
        });
        window.dispatchEvent(dispatchEvent);
        return doesExist;
      },

      // Add item to a specific list
      async addToWishlist(event) {
        const { detail:{ listType, itemId, itemData = {} }} = event;
     
        // Retrieve the list if it exists, otherwise create it
        let list = this.lists.find((list) => list.type === listType);
        if (!list) {
          list = { type: listType, items: [] };
          this.lists.push(list);
        }

        let transformedItem = [];
        if (listType == "products") {
          transformedItem = await shopifyHelper.getShopifyProductList([itemId]);

          const isDuplicate = list.items.some(
            (item) => parseInt(item.id) === parseInt(transformedItem[0].id),
          );
          if (!isDuplicate) {
            list.items = list.items.concat(transformedItem);
    

            const doesExist = list.items.some(
              (item) => parseInt(item.id) == parseInt(itemId),
            );
    
            const dispatchEvent = new CustomEvent("check-wishlist-response", {
              detail: {
                exist: doesExist,
                itemId: itemId
              },
            });
            window.dispatchEvent(dispatchEvent);
    
          } else {
            console.log(
              `${listType} with id ${transformedItem[0].id} already exists.`,
            );
          }

          return this.lists;
        } else {
          itemData.id = itemId;
          transformedItem.push(itemData);
          const isDuplicate = list.items.some(
            (item) => parseInt(item.id) === parseInt(transformedItem[0].id),
          );

          if (!isDuplicate) {
            list.items = list.items.concat(transformedItem);
            console.log(list.items);
            const doesExist = list.items.some(
              (item) => parseInt(item.id) == parseInt(itemId),
            );
            const dispatchEvent = new CustomEvent("check-wishlist-response", {
              detail: {
                exist: doesExist,
                itemId: itemId
              },
            });
            window.dispatchEvent(dispatchEvent);
          } else {
            console.log(
              `${listType} with id ${transformedItem[0].id} already exists.`,
            );
          }
        }


        return this.lists;
      },

      // Remove item from a specific list
      async removeFromWishlist(event) {
        const { detail:{ listType, itemId }} = event;
        console.log(event.detail, 'Remove Item From Wishlist - Global Function');
        console.log(`Removing from ${listType}: ${itemId}`);

        const list = this.lists.find((list) => list.type === listType);
        if (list) {
          list.items = list.items.filter((item) => item.id != itemId);
        }

        const doesExist = list.items.some(
          (item) => parseInt(item.id) == parseInt(itemId),
        );

        const dispatchEvent = new CustomEvent("check-wishlist-response", {
          detail: {
            exist: doesExist,
            itemId: itemId,
            listType: listType
          },
        });

        window.dispatchEvent(dispatchEvent);

        return true;
      },

      // Get customer wishlist for a specific list type
      async getCustomerWishlist(metaobjectId, listType = "products") {
        return this.lists;
        // create data query
        const data = `query {
                        metaobject(id: "gid://shopify/Metaobject/${metaobjectId}") {
                        id
                        handle
                        onlineStoreUrl
                        updatedAt
                        fields {
                            key
                            value
                        }
                      }
                    }
                `;

        const response = await fetch(
          `${helpers.getDomain()}/api/2024-01/graphql.json`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/graphql",
              "X-Shopify-Storefront-Access-Token": api.getStorefrontToken(),
              "X-Shopify-Access-Token": api.getPublicAccessToken(),
            },
            body: data,
          },
        );
        const jsonResponse = await response.json();
        let itemIds = this.extractProductIds(jsonResponse, listType);
        const transformedItems =
          await shopifyHelper.getShopifyProductList(itemIds);
        this.lists = transformedItems;
        this.response = jsonResponse;
        return jsonResponse;
      },

      // Update customer wishlist for a specific list type
      updateCustomerWishlist(metaobjectId, listType = "products") {
        const list = this.lists.find((list) => list.type === listType);
        if (!list) return;

        const query = `mutation UpdateMetaobject($id: ID!, $metaobject: MetaobjectUpdateInput!) {
                    metaobjectUpdate(id: $id, metaobject: $metaobject) {
                        metaobject {
                            handle
                            season: field(key: "product") {
                                value
                            }
                        }
                        userErrors {
                            field
                            message
                            code
                        }
                    }
                }`;

        const variables = `{
                  "id": "gid://shopify/Metaobject/${metaobjectId}",
                  "metaobject": {
                    "fields": [
                      {
                        "key": "${listType}",
                        "value": "[\\"gid://shopify/Product/7802207338649\\"]" // Update this for specific list type
                      }
                    ]
                  }
                }`;

        const response = fetch(
          `${helpers.getDomain()}/api/2024-01/graphql.json`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/graphql",
              "X-Shopify-Storefront-Access-Token": api.getStorefrontToken(),
              "X-Shopify-Access-Token": api.getPublicAccessToken(),
            },
            body: query,
          },
        )
          .then((response) => response.json())
          .then((response) => {
            this.response = response;
            return response;
          });
      },
    };
  },
};

export default wishlist;
