// @ts-nocheck
import React, {useEffect, useState} from "react";

import RecipeTileBadges from "./components/RecipeTileBadges";
import RecipeTileImage from "./components/RecipeTileImage";

type TileProps = {
  recipe: undefined;
  yotpo_snippet: String;
  dummy_id: String;
  wishlist: boolean
};

const FillHeartSVG = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4.31802 6.31802C2.56066 8.07538 2.56066 10.9246 4.31802 12.682L12.0001 20.364L19.682 12.682C21.4393 10.9246 21.4393 8.07538 19.682 6.31802C17.9246 4.56066 15.0754 4.56066 13.318 6.31802L12.0001 7.63609L10.682 6.31802C8.92462 4.56066 6.07538 4.56066 4.31802 6.31802Z" fill="#445B4B" stroke="#445B4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>)
}

export default function ExtendedRecipeTile(props: TileProps) {
  const { recipe, yotpo_snippet, dummy_id, wishlist = false } = props;

  const [inWishlist, setInWishlist] = useState(false);

  const badges =
    recipe.tags && recipe.tags.map((tag) => tag.split("|")).slice(0, 3);

    const dispatchCustomEvent = (eventName, detail) => {
      window.dispatchEvent(new CustomEvent(eventName, { detail }));
    };
  
    const checkWishlistList = () => {
      dispatchCustomEvent("check-wishlist", { listType: 'recipes', itemId: recipe.id });
    };
  
    const handleWishlistResponse = (event) => {
      const {detail: { exist, itemId  } } = event;
      if(  itemId == recipe.id ) {
        console.log(exist, 'handleWishlist');
        setInWishlist(event.detail.exist);
      }
    };
    

    useEffect(() => {
      window.addEventListener("check-wishlist-response", handleWishlistResponse);
      checkWishlistList();
      return () => {
        window.removeEventListener("check-wishlist-response", handleWishlistResponse);
      };
    }, []);
  

  return (
    <div className={`recipe-tile`}>
       { wishlist &&     <div key={`${recipe.title}-${inWishlist}`}>
          { inWishlist ? (
          <button
            className="product-tile__wishlist"
            onClick={() => {
              dispatchCustomEvent("remove-wishlist", { listType: 'recipes', itemId: recipe.id  })
            }
          }
          >
             <FillHeartSVG/>
          </button>
        ) : (
          <button
            className="product-tile__wishlist"
            onClick={() => {
              dispatchCustomEvent("add-wishlist", { listType: 'recipes', itemId: recipe.id, itemData: recipe })
            }}
          >
            <span className="icon-heart text-mb-dark-leaf"></span>
          </button>
        )}
        </div> }

      <div className="recipe-tile__image-wrapper">
        <a href={`${recipe.url}`}>
          <RecipeTileImage
            featuredImageUrl={recipe.featured_image}
            altText={recipe.title}
            images={recipe.images}
          />
        </a>

        {badges && <RecipeTileBadges badges={badges} />}
      </div>

      <div className="recipe-tile__details">
        <div
          className="yotpo-widget-instance h-[21px]"
          data-yotpo-instance-id={`${yotpo_snippet}`}
          data-yotpo-product-id={`${dummy_id}`}
          data-yotpo-cart-product-id={`${dummy_id}`}
          data-yotpo-section-id="product"
        ></div>
        <div className="recipe-tile__details__title-price">
          <a href={`${recipe.url}`}>
            <div className="recipe-tile__details__title">{recipe.title}</div>
          </a>
          <div className="recipe-tile__details__subtitle">
            {" "}
            {recipe.sub_title}{" "}
          </div>
        </div>
        <div className="mt-auto">
          {" "}
          <a href={`${recipe.url}`} className="recipe-tile__details__read">
            {" "}
            Read More{" "}
          </a>{" "}
        </div>
      </div>
    </div>
  );
}
