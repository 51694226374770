import React, { useState, useRef, useCallback, useEffect } from 'react';

import ExtendedTextField from '@src/stories/elements/ExtendedTextField/ExtendedTextField';
import cloneDeep from 'lodash/cloneDeep';
import ExtendedSelectField from '@src/stories/elements/ExtendedSelectField/ExtendedSelectField';
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import { debounce } from 'alpinejs/src/utils/debounce';
import { getCookie } from '../../../../../js/helpers';
import ExtendedTextareaField from '@src/stories/elements/ExtendedTextareaField/ExtendedTextareaField';
import ExtendedCheckbox from '@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox';

const countries = [
  { label: 'Australia', value: 'Australia' },
  { label: 'New Zealand', value: 'New Zealand' },
] as any;

export default function ExtendedCartMultiAddressForm ({
  customer,
  existingAddress,
  translations,
  onSubmit,
  onAddressChange,
  multiAddress,
  item
}) {
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addressType, setAddressType] = useState('Residential');
  const [hasError, setHasError] = useState(false);
  const [isSaved, setIsSaved] = useState(false);


  const { key: addressID } = item;

  let initialRender = false;
  const [savedFormData, setSavedFormData] = useState(multiAddress);

  useEffect(() => {
    const _savedFormData = getCookie('checkoutMultiShippingAddress');
    const multiAddressData = _savedFormData;
    if (multiAddressData) {
      const parsedFormData = JSON.parse(decodeURIComponent(multiAddressData));
      setSavedFormData(parsedFormData);
      onAddressChange(parsedFormData);
      initialRender = true;
    }
  }, []);

  useEffect(() => {
    if (existingAddress) {
      const toFormObject = {
        'address[first_name]': existingAddress?.first_name,
        'address[last_name]': existingAddress?.last_name,
        'address[company]': existingAddress?.company,
        'address[country]': existingAddress?.country,
        'address[phone]': existingAddress?.phone,
        'address[address1]': existingAddress?.address1,
        'address[address2]': existingAddress?.address2,
        'address[city]': existingAddress?.city,
        'address[province]': existingAddress?.province,
        'address[zip]': existingAddress?.zip,
        'address[residentialAddressType]': existingAddress?.residentialAddressType,
        'address[businessAddressType]': existingAddress?.businessAddressType,
        'delivery_instructions': '',
      }

      setSavedFormData({...multiAddress, [addressID]: toFormObject});
      handleAddressChange(true, toFormObject);
    }
  }, [existingAddress]);

  function handleAddressCreate (event) {
    event.preventDefault();
    setIsSubmitting(true);
    setHasError(false);

    const formData = new FormData(formRef.current) as any;
    const formParams = new URLSearchParams(formData).toString();

    fetch('/account/addresses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams
    }).then(response => {
      if (response.ok) {
        return response.text();

      } else {
        return Promise.reject('Failed to submit the form');
      }
    }).then(() => {
      const formObject = Object.fromEntries(formData);

      setHasError(false);
      setIsSaved(true);
      onSubmit(formObject);

    }).catch((error) => {
      console.error('Error:', error);
      setHasError(true);

    }).finally(() => setIsSubmitting(false));
  }

  const handleAddressChange = useCallback(
    debounce((isAddressSaved = false, existingAddress = {}) => {
      const formData = new FormData(formRef.current) as any;
      const formObject = Object.fromEntries(formData);
      console.log('saving');
      if(isAddressSaved) {
        onAddressChange(prevState => ({
          ...prevState,
          [addressID]: existingAddress
        }));
        setSavedFormData(prevState => ({
          ...prevState,
          [addressID]: existingAddress
        }));
      }
      else {
        onAddressChange(prevState => ({
          ...prevState,
          [addressID]: formObject
        }));
        setSavedFormData(prevState => ({
          ...prevState,
          [addressID]: formObject
        }));
      }
   

      setIsSaved(isAddressSaved);
    }, 2000), []
  );

  useEffect(()=> {
    if(!initialRender) {
      const formData = new FormData(formRef.current) as any;
      const formObject = Object.fromEntries(formData);
      const prevFormData = cloneDeep(savedFormData);
      const formMulti = {...prevFormData, [addressID]: formObject};
  
      const formDataJSON = encodeURIComponent(JSON.stringify(formMulti));
      document.cookie = `checkoutMultiShippingAddress=${formDataJSON}; path=/; max-age=604800`;
    }

  }, [savedFormData,  addressID])


  return (
    <form
      ref={formRef}
      className='flex flex-col gap-6 mt-6'
      acceptCharset="UTF-8"
      onChange={() => handleAddressChange()}
      onSubmit={(event) => handleAddressCreate(event)}
      x-data="addressvalidation"
    >
      <input type="hidden" name="form_type" defaultValue="customer_address" />
      <input type="hidden" name="utf8" defaultValue="✓" />
      <div key={`${addressID}`} className='hidden'> {addressID} </div> 
      <div className='lg:flex items-center gap-6'>
        <ExtendedTextField
          name='address[first_name]'
          key={savedFormData[addressID]?.['address[first_name]'] || item.properties?._shipping_first_name}
          value={savedFormData[addressID] && savedFormData[addressID]['address[first_name]'] || item.properties?._shipping_first_name }
          label='First Name'
          placeholder={`${translations?.first_name}`}
          className='flex-1 max-lg:mb-6'
          required
        />

        <ExtendedTextField
          name='address[last_name]'
          key={savedFormData[addressID]?.['address[last_name]'] || item.properties?._shipping_last_name }
          value={savedFormData[addressID] &&  savedFormData[addressID]['address[last_name]'] || item.properties?._shipping_last_name }
          label='Last Name'
          placeholder={`${translations?.last_name}`}
          className='flex-1'
          onChange={() => handleAddressChange()}
          required
        />
      </div>

      <div className='lg:flex items-center gap-6'>
        <ExtendedTextField
          name='address[company]'
          key={savedFormData[addressID]?.['address[company]'] || item.properties?._shipping_company }
          value={ savedFormData[addressID] && savedFormData[addressID]['address[company]'] || item.properties?._shipping_company }
          label='Company'
          placeholder={`${translations?.company}`}
          onChange={() => handleAddressChange()}
          className='flex-1 max-lg:mb-6'
        />

        <ExtendedSelectField
          name='address[country]'
          options={countries}
          key={savedFormData[addressID]?.['address[country]'] || item.properties?._shipping_country }
          value={savedFormData[addressID] && savedFormData[addressID]['address[country]'] || item.properties?._shipping_country }
          label='Country'
          placeholder={`${translations?.country}`}
          xref='address_field'
          className='flex-1'
          required
          onChange={() => handleAddressChange()}
        />
      </div>

      <ExtendedTextField
        name='address[phone]'
        key={savedFormData[addressID]?.['address[phone]'] || item.properties?._shipping_phone }
        value={ savedFormData[addressID] && savedFormData[addressID]['address[phone]'] ||  item.properties?._shipping_phone }
        label='Mobile'
        inputType="number"
        placeholder={`${translations?.mobile}`}
        required
      />

      <div className="flex flex-col">
          <div className="form-field__label"> Address Type </div>
          <div className='flex gap-3 w-full'>
            <div className='flex gap-3 w-full'>
                <p>{'Residential'}</p>   
                <ExtendedCheckbox name="address[residentialAddressType]" 
                  value={`${addressType == 'Residential'}`}
                  checked={addressType == 'Residential'}
                  onChange={(e) => { 
                    setAddressType('Residential');
                    handleAddressChange();
                  }} />
              </div> 
              <div className='flex gap-3 w-full'>       
                <p>{'Business'}</p>          
                <ExtendedCheckbox name="address[businessAddressType]" 
                  value={`${addressType == 'Business'}`}
                  checked={addressType == 'Business'}
                  onChange={(e) => { 
                    setAddressType('Business');
                    handleAddressChange();
                  }} />
              </div>
          </div>
      </div>

      <ExtendedTextField
        name='address[address1]'
        key={savedFormData[addressID]?.['address[address1]'] || item.properties?._shipping_address1 }
        value={ ( savedFormData[addressID] && savedFormData[addressID]['address[address1]'] ) ||  item.properties?._shipping_address1 }
        label='Address'
        placeholder={`${translations?.address1}`}
        onChange={() => {handleAddressChange();}}
        onFocus={() => {handleAddressChange();}}
        onBlur={() => {handleAddressChange();}}
        xref='address_field'
        xinit="setupGooglePlacesAutocomplete"
        required
      />

      <ExtendedTextField
        name='address[address2]'
        key={savedFormData[addressID]?.['address[address2]'] || item.properties?._shipping_address2}
        value={ savedFormData[addressID] && savedFormData[addressID]['address[address2]'] || item.properties?._shipping_address2 }
        placeholder={`${translations?.address2}`}
      />

      <ExtendedTextField
        name='address[city]'
        key={savedFormData[addressID]?.['address[city]'] || item.properties?._shipping_city}
        value={ savedFormData[addressID] && savedFormData[addressID]['address[city]'] || item.properties?._shipping_city }
        onChange={() => handleAddressChange()}
        label='City'
        xref='city'
        placeholder={`${translations?.city}`}
      />

      <ExtendedTextField
        name='address[province]'
        key={savedFormData[addressID]?.['address[province]'] || item.properties?._shipping_province}
        value={savedFormData[addressID] && savedFormData[addressID]['address[province]'] || item.properties?._shipping_province }
        label='State'
        onChange={() => handleAddressChange()}
        xref='province'
        placeholder={`${translations?.state}`}
      />

      <ExtendedTextField
        name='address[zip]'
        key={savedFormData[addressID]?.['address[zip]'] || item.properties?._shipping_zip  }
        value={savedFormData[addressID] && savedFormData[addressID]['address[zip]'] || item.properties?._shipping_zip }
        label='Post Code'
        onChange={() => handleAddressChange()}
        xref='zip'
        placeholder={`${translations?.postcode}`}
        required
      />

      {customer &&
        <>
          {isSaved
            ? <p className='text-mb-green text-sm'>
                Address is saved to address book.
              </p>

            : <ExtendedButton
                type='submit'
                disabled={isSubmitting}
                icon={isSubmitting ? 'loader-alt animate-spin text-xl' : ''}
                label={isSubmitting ? '' : 'Save to Address book'}
                style='primary'
                className='lg:max-w-[200px]'
              />
          }

          {hasError &&
            <p className='text-mb-red text-sm'>
              Form submission failed. Please try again.
            </p>
          }
        </>
      }
    </form>
  )
}
