import React, { useState } from 'react';

import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import ExtendedImage from "../../ExtendedImage/ExtendedImage";
import ExtendedCartItemDetails from "./ExtendedCartItemDetails";
import ExtendedCartMultiAddressInfo from "./ExtendedCartMultiAddressInfo";
import ExtendedCartMultiItemDetails from "./ExtendedCartMultiItemDetails";

import { addToCart, updateItem  } from "frontend/js/useShopifyCart";
import { formatPrice } from 'frontend/js/helpers';


const ImagePlaceholderSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5">
      <path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"/>
      <path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"/><path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"/>
  </svg>
)

export default function ExtendedCartItems ({
  cartItems,
  cardTypes,
  giftWrapTypes,
  optionsInCart,
  hampers,
  hasCards,
  updatingCart,
  isGiftWrapEnabled,
  translations,
  onItemDelete,
  onApplyMessageToAll,
  bundleType,
  characterLimit,
  selectedShippingType,
  onMultiAddressChange,
  customer,
  multiAddress
}) {
  const [isLoading, setIsLoading] = useState(false);
 
  async function handleAddItem(id, index, itemIndex, item) {
    if (isLoading) return;
    const { properties: { _Chilled_Delivery: chilled, _delivery_date_end: deliveryEnd, _delivery_date_start: deliveryStart }} = item;
    const propertiesToAdd = {
      "_itemIndex": `${id}-${index}-${itemIndex}`,
      ...(chilled && { "_Chilled_Delivery": chilled }),
      ...(deliveryEnd && { "_delivery_date_end": deliveryEnd }),
      ...(deliveryStart && { "_delivery_date_start": deliveryStart }),
    };
    setIsLoading(true);
    await addToCart({ id, quantity: 1, properties: propertiesToAdd });
    setIsLoading(false);
  }

async function handleQuantityUpdate(id, quantity) {
  if (isLoading) return;
  setIsLoading(true);
  await updateItem({ id, quantity});
  setIsLoading(false);
}
const orderedItems = cartItems.sort((a, b) => {
  if (a.product_title === null) return 1;  // Move null values to the end
  if (b.product_title === null) return -1; // Move null values to the end
  return a.product_title.localeCompare(b.product_title); // Compare valid titles
});

  if (selectedShippingType == 'SINGLE') {
    return (
      <ul className="items-list flex flex-col flex-grow gap-8">
        {orderedItems.map((lineItem, index) => {
        const hasBundle = lineItem.items.some(item => item.product_type === bundleType);
          return (
            <li
              key={`${lineItem.id}_${index}`}
              className="item"
            >
              <div className='item-details__image-wrap'>
                  { !lineItem.image && <ImagePlaceholderSVG/> }
                  { lineItem.image && (  <ExtendedImage
                    imageUrl={lineItem.image}
                    altText={lineItem.product_title}
                    className={'item-details__image'}
                  /> )}
              </div>

              <div className={`item-details flex-auto gap-6 lg:gap-2`}>
                <div className="item-details__title b3">
                  {lineItem.product_title}
                </div>
                { hasBundle  &&
                <div className={`item__remove b3 ml-auto leading-[1px] hidden lg:block`}>
                    <span
                      className='icon-trash text-2xl'
                      onClick={() => handleQuantityUpdate(lineItem.items[0]?.key, 0)}
                    ></span>
                  </div>
                  }

                <div className="text-sm max-lg:hidden">
                  <span className='font-medium mr-2'>{translations.qty}:</span>

                  {lineItem.items.length}
                </div>

                <div className='flex gap-4 lg:hidden'>
                  <ExtendedImage
                    imageUrl={lineItem.image}
                    altText={lineItem.product_title}
                    className={'item-details__image mobile'}
                  />

                  <div className='flex flex-col gap-3'>
                    <h4 className='font-medium lg:hidden'>
                      {formatPrice(lineItem.price * lineItem.items.length)}
                    </h4>

                    <div className="text-sm">
                      <span className='font-medium mr-2'>{translations.qty}:</span>

                      {lineItem.items.length}
                    </div>
                  </div>
                </div>

                <div className='lg:flex items-center justify-between'>
                  <h5 className='font-medium max-lg:hidden'>
                    {formatPrice(lineItem.price * lineItem.items.length)}
                  </h5>
                  { !hasBundle  &&
                  <ExtendedButton
                    style='secondary'
                    label={isLoading ? translations.adding_item : translations.add_item}
                    disabled={isLoading || updatingCart}
                    icon={isLoading ? 'loader-alt animate-spin text-xl' : ''}
                    onClick={() => handleAddItem(lineItem.variant_id, lineItem.items.length + 1, index, lineItem.items[0])}
                  />
                  }
                </div>

                { !hasBundle  && lineItem.items.map((item, itemIndex) => {
                  return (
                    <ExtendedCartItemDetails
                      key={`${item.variant_id}_${index}_${itemIndex}`}
                      item={item}
                      itemIndex={itemIndex}
                      hasCards={hasCards}
                      cardTypes={cardTypes}
                      giftWrapTypes={giftWrapTypes}
                      optionsInCart={optionsInCart}
                      updatingCart={updatingCart}
                      lineItem={lineItem}
                      isHamper={item.product_type == 'Hampers' && hampers[lineItem.variant_id] && hampers[lineItem.variant_id].length > 0}
                      showGiftWrap={isGiftWrapEnabled}
                      hasOtherItems={lineItem.items.length > 1}
                      translations={translations}
                      characterLimit={characterLimit}
                      onItemDelete={(id, itemIndex) => onItemDelete(id, itemIndex)}
                      onApplyMessageToAll={(cardMessage) => onApplyMessageToAll({ lineItem, cardMessage })}
                    />
                  )
                })}
              </div>
            </li>
          )
        })}
      </ul>
    )
  }
  else if (selectedShippingType == 'MULTI') {
    return (
      <ul className="items-list flex flex-col flex-grow gap-8">
        {orderedItems.map((lineItem, index) => {
        const hasBundle = lineItem.items.some(item => item.product_type === bundleType);
          return (
            <li
              key={`${lineItem.id}_${index}`}
              className="item"
            >
              <div className='item-details__image-wrap'>
                  { !lineItem.image && <ImagePlaceholderSVG/> }
                  { lineItem.image && (  <ExtendedImage
                    imageUrl={lineItem.image}
                    altText={lineItem.product_title}
                    className={'item-details__image'}
                  /> )}
              </div>

              <div className={`item-details flex-auto gap-6 lg:gap-2`}>
                <div className="item-details__title b3">
                  {lineItem.product_title}
                </div>
                { hasBundle  &&
                <div className={`item__remove b3 ml-auto leading-[1px] hidden lg:block`}>
                    <span
                      className='icon-trash text-2xl'
                      onClick={() => handleQuantityUpdate(lineItem.items[0]?.key, 0)}
                    ></span>
                  </div>
                  }

                <div className="text-sm max-lg:hidden">
                  <span className='font-medium mr-2'>{translations.qty}:</span>

                  {lineItem.items.length}
                </div>

                <div className='flex gap-4 lg:hidden'>
                  <ExtendedImage
                    imageUrl={lineItem.image}
                    altText={lineItem.product_title}
                    className={'item-details__image mobile'}
                  />

                  <div className='flex flex-col gap-3'>
                    <h4 className='font-medium lg:hidden'>
                      {formatPrice(lineItem.price * lineItem.items.length)}
                    </h4>

                    <div className="text-sm">
                      <span className='font-medium mr-2'>{translations.qty}:</span>

                      {lineItem.items.length}
                    </div>
                  </div>
                </div>

                <div className='lg:flex items-center justify-between'>
                  <h5 className='font-medium max-lg:hidden'>
                    {formatPrice(lineItem.price * lineItem.items.length)}
                  </h5>
                  { !hasBundle  &&
                  <ExtendedButton
                    style='secondary'
                    label={isLoading ? translations.adding_item : translations.add_item}
                    disabled={isLoading || updatingCart}
                    icon={isLoading ? 'loader-alt animate-spin text-xl' : ''}
                    onClick={() => handleAddItem(lineItem.variant_id, lineItem.items.length + 1, index, lineItem.items[0])}
                  />
                  }
                </div>

                { !hasBundle  && lineItem.items.map((item, itemIndex) => {
                  return (
                    <ExtendedCartMultiItemDetails
                      key={`${item.variant_id}_${index}_${itemIndex}`}
                      item={item}
                      customer={customer}
                      itemIndex={itemIndex}
                      hasCards={hasCards}
                      cardTypes={cardTypes}
                      giftWrapTypes={giftWrapTypes}
                      optionsInCart={optionsInCart}
                      updatingCart={updatingCart}
                      onMultiAddressChange={(formObject) => onMultiAddressChange(formObject)}
                      multiAddress={multiAddress}
                      lineItem={lineItem}
                      isHamper={item.product_type == 'Hampers'}
                      showGiftWrap={isGiftWrapEnabled}
                      hasOtherItems={lineItem.items.length > 1}
                      translations={translations}
                      characterLimit={characterLimit}
                      onItemDelete={(id, itemIndex) => onItemDelete(id, itemIndex)}
                      onApplyMessageToAll={(cardMessage) => onApplyMessageToAll({ lineItem, cardMessage })}
                    />
                  )
                })}
              </div>
            </li>
          )
        })}
      </ul>
    )
  }
}