export const hasBodyClass = (className) => {
  return document.body.classList.contains(className);
};

export function formatPrice(priceInCents, currency = "", isCents = true) {
  const price = isCents ? priceInCents / 100 : priceInCents;

  return currency != ""
    ? `${currency}${price.toFixed(2)}`
    : new Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD",
      }).format(price);
}

export function appendPrice(priceInCents, currency) {
  const price = priceInCents / 100;
  return `${currency}${price.toFixed(2)}`;
}

export function lockScroll(lock = true) {
  const action = lock ? "add" : "remove";
  document.body.classList[action]("overflow-hidden");
}

export function formatPriceWithoutTrailingZeros(price, currency = "") {
  let formattedPrice = formatPrice(price, currency);

  if (price % 1 === 0) {
    formattedPrice = formattedPrice.replace(".00", "");
  }

  return formattedPrice;
}

export function interpolateTranslation(translations, key, variable, value) {
  if (!translations) return value;
  if (!translations[key]) return value;
  return translations[key].replace(`{{ ${variable} }}`, value);
}

export function capitalizeWords(string) {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function formatDateTime(dateString) {
  const date = new Date(dateString);

  const options = {
    timeZone: "Australia/Sydney",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-AU", options).format(date);
}

export function stripTime(dateString) {
  const dateTime = new Date(dateString);
  const dateOnly = dateTime && dateTime.toISOString().split("T")[0];
  return dateOnly;
}

export function uniqueAddressIdGenerator( address1, city, province, zip, country )  {
  const uniqueId = `${address1}|${city}|${province}|${zip}|${country}`;
  return uniqueId
}



export function shortCodeParser(
  string,
  quantity = 1,
  unitState = "metric",
  productAddList = {},
  baseQuantity,
) {
  const CONTENT_SHORTCODE_ENUM = ["[TIP]", "[IMG]", "[VID]", "[HEADING]"];

  const FORMAT_SHORTCODE_ENUM = ["G", "ML", "QTY", "URL", "PRODUCT"];

  const TAG_TO_CUP_SHORTCODE_ENUM = ["G", "ML"];

  const UNIT_TO_CUP_SHORTCODE_ENUM = ["g", "ml"];

  // Boolean for quick add to cart detection
  let isProduct = false;
  let productId = "";

  function modifyContent(tag, content, quantity, unit, baseQuantity) {
    if (FORMAT_SHORTCODE_ENUM.includes(tag)) {
      if (tag == "URL") {
        const parsedURL = content.split("|");
        return `<a href="${parsedURL[1]}">${parsedURL[0]}</a>`;
      }

      if (tag == "PRODUCT" && productAddList) {
        const parsedURL = content.split("|");
        const regex = /{(\d+)}/;
        const match = parsedURL[1].match(regex);
        isProduct = true;
        const extractedString = match ? match[1] : null;
        productId = extractedString;
        return `<a href="/products/${productAddList[extractedString]?.handle}">${parsedURL[0]}</a>`;
      }

      let number = parseFloat(content, 10);

      if (unitState == "metric") {
        return (
          parseFloat(
            (number * (quantity / baseQuantity)).toFixed(2),
          ).toString() + unit
        );
      } else {
        if (
          TAG_TO_CUP_SHORTCODE_ENUM.includes(tag) &&
          UNIT_TO_CUP_SHORTCODE_ENUM.includes(unit)
        ) {

          const convertedNumber = parseFloat(
            (number / 250) * (quantity / baseQuantity),
          ).toFixed(2);

          return (
            parseFloat(convertedNumber).toString() +
            `${convertedNumber > 1 ? " cups" : " cup"}`
          );
        } else {
          return (
            parseFloat(
              (number * (quantity / baseQuantity)).toFixed(2),
            ).toString() + unit
          );
        }
      }
    }
    return content;
  }

  const pattern = /\[(\w+)\](.*?)\[\/\1\](\w*)/g;

  for (const shortcode of CONTENT_SHORTCODE_ENUM) {
    if (string.startsWith(shortcode)) {
      const shortcodePattern = new RegExp(
        `^\\${shortcode}(.*?)\\${shortcode.replace("[", "[/")}$`,
      );
      const shortcodeMatch = string.match(shortcodePattern);
      if (shortcodeMatch) {
        return {
          parsedString: shortcodeMatch[1],
          isContent: true,
          tag: shortcode,
          isProduct,
          productId,
        }; // Return the content inside the matched tags
      }
    }
  }

  const parsedText = string.replace(pattern, (match, tag, content, unit) => {
    if (modifyContent) {
      content = modifyContent(tag, content, quantity, unit, baseQuantity);
    }
    return content;
  });

  return {
    parsedString: parsedText,
    isContent: false,
    tag: null,
    isProduct,
    productId,
  };
}

export function isVideoUrl(url) {
  const videoExtensions = [".mp4", ".mov"];
  return videoExtensions.some((extension) => url.endsWith(extension));
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export const recipientProperties = {
  _recipient_name: "",
  _card_type: "",
  _card_message: "",
  _gift_wrap_type: "",
  _is_card_message_blank: false,
};

export function constructFullAddress(address) {
  return [
    address["address[address1]"],
    address["address[address2]"],
    address["address[city]"],
    address["address[province]"],
    address["address[zip]"],
    address["address[country]"],
  ]
    .filter(Boolean)
    .join(", ");
}

export default {
  /**
   * Emit a custom event
   * @param  {String} type   The event type
   * @param  {Object} detail Any details to pass along with the event
   * @param  {Node}   elem   The element to attach the event to
   */
  emitEvent(type, detail = {}, elem = document) {
    if (!type) return;

    let event = new CustomEvent(type, {
      bubbles: true,
      cancelable: true,
      detail: detail,
    });

    return elem.dispatchEvent(event);
  },
  getDomain(use_local = true, use_shop = false) {
    if (use_local) {
        if (
            location.origin.includes("127.0.0.1") ||
            location.origin.includes("localhost")
        ) {
            return location.origin;
        }
    }
    if(use_shop) return `https://maggie-beer.myshopify.com`; 
    return `https://${window.location.host}`;
  },
  randomNumber(min = 0, max = 1000) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  },
  debounce(fn, wait) {
    let t;
    return (...args) => {
      clearTimeout(t);
      t = setTimeout(() => fn.apply(this, args), wait);
    };
  },
  truncateLongTitle(input) {
    return input.length > 5 ? `${input.substring(0, 18)}...` : input;
  },
  async fetchHTML(endpoint) {
    return await fetch(endpoint)
      .then((response) => response.text())
      .then((responseText) => {
        return new DOMParser().parseFromString(responseText, "text/html");
      });
  },

  updateUrlParameters(originalUrl, params) {
    const url = new URL(originalUrl.toString());

    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, value.toString());
      }
    }

    return url.toString();
  },

  arrayToEnum(array, keyField) {
    return array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {});
  },

  isValidEmail(email) {
    // Define a regular expression for validating an Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  },

  isValidPhoneNumber(phoneNumber) {
    // Define a regular expression for validating a phone number with spaces, dashes, or parentheses
    const phoneRegex = /^\+?([0-9]{1,3})?[\s\-]?[1-9][0-9\s\-\(\)]{6,}$/;

    // Test the phone number against the regular expression
    return phoneRegex.test(phoneNumber);
  },
};
