import React, { useState, useEffect } from "react";
import ExtendedProductTile from "@src/stories/components/ExtendedProductTile/ExtendedProductTile";
import ExtendedRecipeTile from "@src/stories/components/ExtendedRecipeTile/ExtendedRecipeTile";

export const WishlistItems = ({ settings, selectedSort, wishlistItems, filter, wishlist }) => {
  console.log(wishlistItems);
  const sortedWishlist = wishlistItems.sort((a, b) => {
    if (selectedSort == 'descending') {
        return(b.title.localeCompare(a.title))
    }
    return(a.title.localeCompare(b.title))
  });

  return (
    <div className={`bg-inherit-parent my-4 grid grid-cols-2 bg-mb-white lg:grid-cols-4 ${filter == "recipes" ? 'wishlist__items-recipes': ''}`}>
      {filter == "products" &&
           sortedWishlist.map((item) => (
            <ExtendedProductTile product={item} currentVariant={item?.variants[0]} hideWishlist={!wishlist}/>
          ))
      }
      { filter == "recipes" &&
       sortedWishlist.map((item) => (
            <ExtendedRecipeTile
              recipe={item}
              yotpo_snippet={""}
              dummy_id={item.dummy_id}
              wishlist={wishlist}
            />
      ))}
    </div>
  );
};
