import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import ExtendedCartStep1 from './components/ExtendedCartStep1';
import ExtendedCartStep2 from './components/ExtendedCartStep2';
import ExtendedCartStep3 from './components/ExtendedCartStep3';

import { useCart } from 'frontend/js/useShopifyCart';
import { recipientProperties } from 'frontend/js/helpers';

import './ExtendedCart.scss';
import { uniqueAddressIdGenerator } from "frontend/js/helpers";

const STEPS = [
  ExtendedCartStep1,
  ExtendedCartStep2,
  ExtendedCartStep3
]


const BUNDLE_TYPE = 'Bundle Container';

function constructNewLineItem (lineItem, index) {
  const isNew = !Object.hasOwn(lineItem.properties, '_recipient_name') || !Object.hasOwn(lineItem.properties, '_itemIndex');
  return {
    ...lineItem,
    isNew: isNew && index !== 0,
    properties: {
      ...recipientProperties,
      ...lineItem.properties
    }
  }
}

function groupSimilarVariants ({ cartItems, card_types, gift_wrap_types }) {
  const groupedCartItems: any = [];
  const options = {}
  const reversed = cartItems.reverse();

  for (const cartItem of reversed) {
    const isCardType = card_types && card_types.find(type => type.id === cartItem.variant_id);
    const isGiftWrapType = gift_wrap_types && gift_wrap_types.find(type => type.id === cartItem.variant_id);

    if (!isCardType && !isGiftWrapType) {
      const itemsFromQuantity = [...Array(cartItem.quantity)];
      const items = itemsFromQuantity.map((_, index) => constructNewLineItem(cartItem, index))
      const groupedCartItem = groupedCartItems.find((item: any) => item.variant_id === cartItem.variant_id);

      if (groupedCartItem) {
        groupedCartItem.items = groupedCartItem.items.concat(items);

      } else {
        groupedCartItems.push({
          variant_id: cartItem.variant_id,
          product_title: cartItem.product_title,
          price: cartItem.price,
          image: cartItem.image,
          items
        })
      }
    } else {
      options[cartItem.variant_id] = cartItem
    }
  }

  return { groupedCartItems, options }
}

export default function ExtendedCart({
  customer,
  card_types,
  gift_wrap_types,
  hampers,
  settings,
  shipping_id,
  translations,
  shipping_rates,
  logged_in_threshold,
  logged_out_threshold,
  free_price_bypass
}) {
  const { cart } = useCart();
  const { groupedCartItems, options} = groupSimilarVariants({
    cartItems: cart.items,
    card_types,
    gift_wrap_types
  });

  const [deliveryMap, setDeliveryMap] = useState({});
  const [ chilledDeliveryMap,  setChilledDeliveryMap ] = useState({});

  const [cartItems, setCartItems] = useState(groupedCartItems);
  const [optionsInCart, setOptionsInCart] = useState(options);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage ] = useState("");
  const [hasCards, setHasCards] = useState(!!Object.keys(options).length);

  const [address, setAddress] = useState({
    "address[address1]": "173 Roseburn Place Invercargill Airport Invercargill",
    "address[address2]": "",
    "address[city]": "",
    "address[company]": "Company B.",
    "address[country]": "New Zealand",
    "address[first_name]": "Dev",
    "address[last_name]": "Test 3",
    "address[phone]": "",
    "address[province]": "",
    "address[zip]": "9810",
    "delivery_instructions": "",
    'address[businessAddressType]': false,
    'address[residentialAddressType]': true
  });

  const [multiAddress, setMultiAddress] = useState({});

  const uniqueAddresses = {};

  const addressesObject = {};
  
  // Loop over each cart item to build the address structure
  cart.items.forEach(item => {
    const { id, properties } = item;
    const key = item.key;
  
    // Create the address structure based on properties
    addressesObject[key] = {
      "address[address1]": properties._shipping_address1,
      "address[address2]": properties._shipping_address2,
      "address[city]": properties._shipping_city,
      "address[company]": properties._shipping_company,
      "address[country]": properties._shipping_country,
      "address[first_name]": properties._shipping_first_name,
      "address[last_name]": properties._shipping_last_name,
      "address[phone]": properties._shipping_phone,
      "address[province]": properties._shipping_province,
      "address[zip]": properties._shipping_zip
    };
  });



  for (const address of Object.values(addressesObject)) {
    // Create a unique identifier based on relevant address fields
    const uniqueId = uniqueAddressIdGenerator(address["address[address1]"], address["address[city]"], address["address[province]"], address["address[zip]"], address["address[country]"]);
    // Add the address to uniqueAddresses if it's not already present
    if (!uniqueAddresses[uniqueId]) {
        uniqueAddresses[uniqueId] = address;
    }
}

  const shippingTypes: any = [
    {
      label: translations.sent_to_one_address,
      value: 'SINGLE',
    },
    {
      label: translations.sent_to_multiple_address,
      value: 'MULTI',
    }
  ];
  const [selectedShippingType, setSelectedShippingType] = useState(shippingTypes[0].value);


  const [currentStep, setCurrentStep] = useState(0);
  const StepComponent = STEPS[currentStep];

  useEffect(() => {
    const { groupedCartItems, options} = groupSimilarVariants({
      cartItems: cart.items,
      card_types,
      gift_wrap_types
    });

    if (Object.keys(options).length) {
      setHasCards(true);
    }

    setOptionsInCart(prevOptionsInCart => {
      return {
        ...options,
        ...prevOptionsInCart
      }
    });

    setCartItems((prevCartItems) => {
      if (!prevCartItems.length) return groupedCartItems.filter((cartItem) => cartItem.variant_id !== Number(shipping_id));
      return groupedCartItems.map((cartItem) => {
        const prevCartItem = prevCartItems.find(prevCartItem => prevCartItem.variant_id === cartItem.variant_id);

        if (prevCartItem) {
          const prevCartItemCopy = cloneDeep(prevCartItem);
          
          for (const item of cartItem.items) {
            const prevItemIndex = prevCartItemCopy.items.findIndex(prevItem => prevItem.key === item.key);

            if (prevItemIndex >= 0) {
              const prevItem = prevCartItem.items.splice(prevItemIndex, 1);

              if (prevItem.length) {
                item.properties = {
                  ...item.properties,
                  ...prevItem[0].properties
                }
              }
            }
          }
        }

        return cartItem;
      }).filter((cartItem) => cartItem.variant_id !== Number(shipping_id))
    });
  }, [cart, currentStep]);

  if(cart && cart.token && cart.items.length == 0) {
    return (
    <div className="container py-32 text-center">
       {translations.empty_cart}
    </div>
    )
  }

  return (
    <StepComponent
      cart={cart}
      cartItems={cartItems}
      optionsInCart={optionsInCart}
      hasCards={hasCards}
      customer={customer}
      card_types={card_types}
      gift_wrap_types={gift_wrap_types}
      hampers={hampers}
      settings={settings}
      shipping_id={Number(shipping_id)}
      translations={translations}
      address={address} 
      multiAddress={multiAddress} 
      uniqueAddresses={uniqueAddresses} // Mapping for unique address between all items on multi address cart
      onClickAddCards={(value) => setHasCards(value)}
      onCartItemsChange={(cartItems) => setCartItems(cartItems)}
      onAddressChange={(address) => setAddress(address)}
      onMultiAddressChange={(multiAddress) => setMultiAddress(multiAddress)}
      onStepChange={(stepIndex) => setCurrentStep(stepIndex)}
      deliveryMap={deliveryMap}
      setDeliveryMap={setDeliveryMap}
      chilledDeliveryMap={chilledDeliveryMap} 
      setChilledDeliveryMap={setChilledDeliveryMap}
      setSelectedShippingType={setSelectedShippingType}
      selectedShippingType={selectedShippingType}
      shippingTypes={shippingTypes}
      bundleType={BUNDLE_TYPE}
      shipping_rates={shipping_rates}
      error={error}
      setError={setError}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      logged_in_threshold={logged_in_threshold}
      logged_out_threshold={logged_out_threshold}
      free_price_bypass={free_price_bypass}
    />
  );
};
