import React, { useState } from "react";

const SORT_OPTIONS = [
  { value: "default", label: `Default` },
  { value: "ascending", label: `Name ( A - Z )` },
  { value: "descending", label: `Name ( Z - A )` }
]

export const WishlistActions = ({ settings, selectedSort, setSelectedSort, currentFilter, wishlistItems, }) => {
  const { section, blocks } = settings;
  const [displayLabel, setDisplayLabel] = useState(`Sort By`);


  function handleSelectChange (event) {
    const { value } = event.target;
    setSelectedSort(value);
    const selected = SORT_OPTIONS.find(option => option.value === value);
    if(selected?.value == "default") {
        setDisplayLabel(`Sort By`);
    }
    else  setDisplayLabel(selected?.label || `Sort By`);
  }

  const dispatchCustomEvent = (eventName, detail) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };



  return (
    <div className="wishlist__actions hidden lg:flex lg:mx-6 gap-8">
     { currentFilter === "products" && 

     <button className={`button button-primary`}
     onClick={() => {
      dispatchCustomEvent("add-to-cart-bulk", { products: wishlistItems });
     }}
     >Add all to cart</button> }
      <div className="wishlist__actions-sort-wrap">
        <div className="wishlist__actions-sort">
          <span className="wishlist__actions-sort__display-label">
            {displayLabel}
          </span>

          <select
            defaultValue={selectedSort}
            className="wishlist__actions-sort__field"
            onChange={(event) => handleSelectChange(event)}
          >
            {SORT_OPTIONS.map((option, index) => {
              return (
                <option
                  key={option.value+index}
                  value={option.value}
                >
                  {option.label}
                </option>
              )
            })}
          </select>

          <i key={selectedSort} className="icon-chevron-down" style={{
          right: `${selectedSort == 'default' && '45px'}` }}> </i>
        </div>
      </div>
    </div>
  );
};
