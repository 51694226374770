import React, { useState, useEffect } from "react";

export const WishlistEmpty = ({ settings }) => {
  const { section, blocks } = settings;

  return (
    <>
      <div className="flex justify-center m-6">There are no products in your wishlist.</div>
    </>
  );
};
