import React, { useState, useEffect } from "react";
import ActionBar from "@stories/sections/ExtendedCollectionBanner/ActionBar";

export const WishlistHeader = ({ settings }) => {
  const { globals: { wishlist: { bg_image , color_schema, image }} } = settings;
  return (
    <div className="relative w-full h-full justify-end items-end">
      <div className="flex text-center h-[400px] overflow-hidden flex-col-reverse lg:flex-row"> 
      <div
        className={`left-panel relative flex basis-[40%] flex-col px-4 lg:px-12 justify-center color-${color_schema}`}
        style={{
          backgroundImage: `url(${bg_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
          <div className='text-left'>  <h1 className="h3"> Your Wishlist </h1> </div>
          <div className='pt-4'>
            <ActionBar label={'Share'} altLinks={{
              x_url: settings?.x_url,
              facebook_url: settings?.facebook_url,
              instagram_url: settings?.instagram_url,
              linkedin_url: settings?.linkedin_url,
            }}/>
          </div>
        </div>
        <div className="right-panel basis-[60%] relative flex w-full">
            <img className="absolute top-0 left-0 object-cover h-full w-full" src={image} alt=""/>
        </div>
      </div> 
    </div>
  );
};
