import React from "react";
import { useState, useRef, useEffect } from "react";

import "./Wishlist.scss";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import { WishlistHeader } from "./WishlistHeader";
import { WishlistActions } from "./WishlistActions";
import { WishlistFilters } from "./WishlistFilters";
import { WishlistEmpty } from "./WishlistEmpty";
import { WishlistItems } from "./WishlistItems";
import WishlistManager from "./WishlistManager";

const Wishlist = ({ settings }) => {
  const { globals: { enable_wishlist }} = settings;

  if(!enable_wishlist) return false;

  const [wishlistItems, setWishlistItems] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("products");
  const [ selectedSort, setSelectedSort ] = useState("default");

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);
  const showWelcomeMessageHeader = false;
  const showItemsMessage = false;
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const requestWishlistList = (listType) => {
    console.log("Requesting for wishlist list:", listType);
    setCurrentFilter(listType);
    const event = new CustomEvent("requestWishlistProducts", {
      detail: {
        type: listType,
        message: `Requesting wishlist ${listType}`,
      },
    });
    window.dispatchEvent(event);
  };


  const handleWishlistResponse = (event) => {
    const {detail: { exist, listType } } = event;
    if(!exist) { 
      requestWishlistList(listType);
    } 
  };  

  useEffect(() => {
    if (currentFilter) {
      requestWishlistList(currentFilter);
    }
  }, [currentFilter]);

  useEffect(() => {
    setCurrentFilter("products");
  }, []);


  useEffect(() => {
    let receivedInitialResponse = false;

    const handleWishlistProducts = (event) => {
      setWishlistItems(event.detail.items);
      console.log("Wishlist Items received.", event.detail.items);
      receivedInitialResponse = true; // Mark as received
      setCurrentFilter(event.detail.listType);
    };


    window.addEventListener("responseWishlistProducts", handleWishlistProducts);

    // Initial dispatch
    requestWishlistList("products");


    const retryTimeout = setTimeout(() => {
      if (!receivedInitialResponse) {
        console.log("Retrying request for wishlist list on initial render...");
        requestWishlistList("products");
      }
    }, 100); // Adjust retry delay if necessary

    return () => {
      clearTimeout(retryTimeout);
      window.removeEventListener("responseWishlistProducts", handleWishlistProducts);
    };
  }, []);


  useEffect(() => {
    window.addEventListener("check-wishlist-response", handleWishlistResponse);
    return () => {
      window.removeEventListener("check-wishlist-response", handleWishlistResponse);
    };
  }, []);
 

  return (
    <div className="wishlist-container" >
      {<WishlistHeader settings={settings} />}
      <div className="lg:max-w-[1440px] lg:mx-auto">
      <WishlistFilters
        settings={settings}
        openModal={openModal}
        onFilterClick={requestWishlistList}
        currentFilter={currentFilter} 
      />
      { <WishlistActions  wishlistItems={wishlistItems} settings={settings} currentFilter={currentFilter} selectedSort={selectedSort} setSelectedSort={setSelectedSort} />}

      {isModalVisible && (
        <WishlistManager settings={settings} onClose={closeModal} />
      )}
      {wishlistItems.length > 0 && (
        <div className="wishlist__items py-2">
          {showItemsMessage && (
            <div className="wishlist__items-message py-2">
              Below are items that you've added to your wishlist
            </div>
          )}

          <WishlistItems
            wishlist={enable_wishlist}
            settings={settings}
            wishlistItems={wishlistItems}
            filter={currentFilter}
            selectedSort={selectedSort}
          />
        </div>
      )}

      {wishlistItems.length <= 0 && <WishlistEmpty settings={settings} />}
      </div>
    </div>
  );
};

export default Wishlist;
