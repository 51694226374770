import React, { useState} from "react";
import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";

export const WishlistFilters = ({
  settings,
  currentFilter,
  onFilterClick = (filterType) => {},
}) => {
  const { section, blocks } = settings;
  const filters = [
    {
      name: "Products",
      type: "products",
    },
    {
      name: "Recipes",
      type: "recipes",
    }
  ];

  const options = filters.map((filter) => ({value: filter.type, label: filter.name }));

  function handleFilter(filterType) {
    console.log(filterType);
    if (onFilterClick) onFilterClick(filterType);
  }

  return (
    <div className="wishlist__filters w-full px-6">
      <div className="wishlist__filters-container hidden lg:flex">
        {filters.map((filter, index) => (
          <button
            key={index}
            className={`wishlist__filters-filter ${ currentFilter == filter.type ? 'active' : '' }`}
            onClick={() => handleFilter(filter.type)}
          >
            {filter.name}
          </button>
        ))}
      </div>
      
      <ExtendedSelectField
              options={options}
              className={"w-full flex lg:hidden"}
              placeholder={currentFilter == 'products' ? 'Products': 'Recipes'}
              onChange={(target)=> handleFilter(target.value)}
        />
    </div>
  );
};
