import React, { useState, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';

import ExtendedTextField from '@src/stories/elements/ExtendedTextField/ExtendedTextField';
import ExtendedCheckbox from '@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox';
import ExtendedTextareaField from '@src/stories/elements/ExtendedTextareaField/ExtendedTextareaField';
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import ExtendedCustomSelectField from './ExtendedCustomSelectField';
import ExtendedCollapsible from "@src/stories/components/ExtendedCollapsible/ExtendedCollapsible";
import ExtendedCartMultiAddressInfo from './ExtendedCartMultiAddressInfo';
import { updateItems } from "frontend/js/useShopifyCart";
import { debounce } from 'alpinejs/src/utils/debounce';

const BUNDLE_TYPE = 'Bundle Container';


export default function ExtendedCartMultiItemDetails ({
  item,
  itemIndex,
  hasCards,
  cardTypes,
  giftWrapTypes,
  optionsInCart,
  updatingCart,
  showGiftWrap,
  hasOtherItems,
  translations,
  onItemDelete,
  onApplyMessageToAll,
  lineItem,
  isHamper,
  characterLimit,
  customer,
  onMultiAddressChange,
  multiAddress
}) {

  const [isLoading, setIsLoading] = useState(false);
  const [hasCard, setHasCard] = useState(!!item.properties._card_type);
  const [emptyMessage, setEmptyMessage] = useState(item.properties._is_card_message_blank);
  const [isValid, setisValid] = useState(false);

  async function handleQuantityUpdate(item, itemIndex) {
    if (isLoading) return;

    setIsLoading(true);
    const updates = {}

    updates[`${item.key}`] = 0;

    if (item.properties._card_type) {
      const optionInCart = optionsInCart[item.properties._card_type];
      if (optionInCart) {
        optionInCart.quantity -= 1

        if (optionInCart.key) {
          updates[`${optionInCart.key}`] = optionInCart.quantity;
        }
      }
    }

    if (item.properties._gift_wrap_type) {
      const optionInCart = optionsInCart[item.properties._gift_wrap_type];

      if (optionInCart) {
        optionInCart.quantity -= 1

        if (optionInCart.key) {
          updates[`${optionInCart.key}`] = optionInCart.quantity;
        }
      }
    }

    await updateItems({ updates });

    onItemDelete(item.variant_id, itemIndex);
    setIsLoading(false);
  }

  const handleChange = useCallback(
    debounce((event, item) => {
      const value = event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

      item.properties[event.target.name] = value;
    }, 500), []
  );

  function handleCardTypeChange (value, item, name) {
    handleSelectChange(value, item, name);
    if(value == 0) {
      setHasCard(false);
    }
    else {
      setHasCard(!!value);
    }
  }

  function handleSelectChange (value, item, name) {
    // Wipe Card Message on Cart Type reset
    if(name == '_card_type' && value == 0) {
      item.properties['_card_message'] = '';
    }
    if (item.properties[name] !== value) {
      if (value) {
        if (optionsInCart[value]) {
          optionsInCart[value].quantity += 1;
          optionsInCart[value].isUpdated = true;

        } else {
          optionsInCart[value] = {
            id: value,
            quantity: 1,
            isNew: true,
          }
        }

      } else if (optionsInCart[item.properties[name]]) {
        optionsInCart[item.properties[name]].quantity -= 1;
        optionsInCart[item.properties[name]].isUpdated = true;
      }

      item.properties[name] = value;
    }


  }

  const handleCheck = (event) => {
    console.log(event.target.checked);
    setEmptyMessage(event.target.checked);
  }

  const collapsibleRef = useRef(null);
  const [dropdown, setDropdown] = useState(true);

  const handleToggle = () => {
    if (collapsibleRef.current) {
      collapsibleRef.current.toggle();
    }
    setDropdown(!dropdown);
  };

  useEffect(() => {
    function isAddressValid(item, addresses){
      const address = addresses[item.key];
      const {properties: { _shipping_address1, _shipping_city , _shipping_country, _shipping_first_name, _shipping_last_name, _shipping_phone, _shipping_province,  _shipping_zip} } = item
      if (_shipping_address1 && _shipping_city && _shipping_country && _shipping_first_name && _shipping_last_name && _shipping_phone && _shipping_province && _shipping_zip ) return true;
      if (!address) return false;
  
      // Check required fields are non-empty
      const requiredFields = [
          "address[first_name]",
          "address[last_name]",
          "address[country]",
          "address[phone]",
          "address[address1]",
          "address[city]",
          "address[province]",
          "address[zip]"
      ];
  
      return requiredFields.every(field => address[field] && address[field] !== "");
  }
  
  setisValid(isAddressValid(item, multiAddress));
  
  }, [multiAddress]);

  return (
    <>
      <hr className={clsx('bg-mb-neutral-500 my-2',{
        'max-lg:hidden': itemIndex === 0
      })} />
      <div className={`p-4 extended-cart__multi-items border rounded-lg shadow-small flex flex-col gap-2   ${clsx({
            "border-mb-red extended-cart__multi-items-error": !isValid,
            "border-mb-green extended-cart__multi-items-valid": isValid,
            "border-mb-neutral-500": false
          })}`}>    
        
        <div className='flex items-center justify-between gap-4'>
          <div className="flex items-center gap-2">
            
            { isValid && <span className="icon icon-check-circle text-[18px] text-mb-green"> </span> }
            { !isValid && <span className="icon icon-x-circle text-[18px] text-mb-red"> </span> }
            <div className='extended-cart__multi-items-title'>
              {isHamper ? translations.hamper : translations.item } {itemIndex + 1}
            </div>
          </div>
          <div className="flex flex-row gap-[21px] items-center"> 
            <span
              className={clsx('text-2xl cursor-pointer', {
                'icon-trash': !isLoading,
                'icon-loader-alt animate-spin': isLoading
              })}
              onClick={() => handleQuantityUpdate(item, itemIndex)}
            ></span>

            <span className={`cursor-pointer text-[24px] icon ${ dropdown ? 'icon-chevron-up' : 'icon-chevron-down' }`} onClick={handleToggle}> </span>  
          </div>
        </div>
                
        <ExtendedCollapsible
          key={`${itemIndex}-collapsible`}
          active
          ref={collapsibleRef}
        >
          <form
            className='flex flex-col gap-4'
            onSubmit={(event) => event.preventDefault()}
            onChange={(event) => handleChange(event, item)}
          > 
          {hasCards &&
            <div className="mb-5">
              <div className='flex max-lg:flex-col gap-4 lg:gap-6'>
              { isHamper && <ExtendedCustomSelectField
                  name='_card_type'
                  placeholder='Select a card (optional)'
                  label={`Card ${itemIndex + 1}`}
                  options={cardTypes}
                  disabled={isLoading || updatingCart}
                  key={`card_type_${item.properties._card_type}`}
                  value={item.properties._card_type}
                  hasError={item.formErrors?.card_type}
                  errorMessage={item.formErrors?.card_type}
                  className='flex-1'
                  lineItem={lineItem}
                  onChange={(value) => handleCardTypeChange(value, item, '_card_type')}
                />
              }
                {showGiftWrap && isHamper &&
                  <ExtendedCustomSelectField
                    name='_gift_wrap_type'
                    label={`Gift wrapping ${itemIndex + 1}`}
                    options={giftWrapTypes}
                    lineItem={lineItem}
                    placeholder='Select a gift wrap (optional)'
                    disabled={isLoading || updatingCart}
                    key={`gift_wrap_type_${item.properties._gift_wrap_type}`}
                    value={item.properties._gift_wrap_type}
                    hasError={item.formErrors?.gift_wrap_type}
                    errorMessage={item.formErrors?.gift_wrap_type}
                    className='flex-1'
                    onChange={(value) => handleSelectChange(value, item, '_gift_wrap_type')}
                  />
                }
              </div>
              
              {hasCard &&
                <div className="mt-5">
                  <div className='flex items-center justify-between gap-4 mb-2'>
                    <div className='text-sm font-medium'>
                      Type your message...
                    </div>

                    <ExtendedCheckbox
                      className='item-details__blank-checkbox desktop'
                      key={`is_card_message_blank_${item.properties._is_card_message_blank}`}
                      checked={emptyMessage}
                      onChange={(event)=> { handleCheck(event) }}
                      disabled={isLoading || updatingCart}
                      name='_is_card_message_blank'
                      label='I would like it to be left blank'
                    />
                  </div>
                  <div className="extended-cart_message-field"> 
                    <ExtendedTextareaField
                      name='_card_message'
                      key={`card_message_${item.properties._card_message}`}
                      disabled={ emptyMessage || isLoading || updatingCart}
                      value={emptyMessage ? '' : item.properties._card_message }
                      required={!item.properties._is_card_message_blank}
                      maxCharacters={characterLimit}
                      hasError={item.formErrors?.card_message}
                      errorMessage={item.formErrors?.card_message}
                    />
                  </div>

                  <ExtendedCheckbox
                    className='item-details__blank-checkbox mobile'
                    key={`is_card_message_blank_${item.properties._is_card_message_blank}`}
                    checked={emptyMessage}
                    onChange={(event)=> { handleCheck(event) }}
                    disabled={ emptyMessage || isLoading || updatingCart}
                    name='_is_card_message_blank'
                    label='I would like it to be left blank'
                  />

                  {hasOtherItems &&
                    <ExtendedButton
                      type='button'
                      label='Apply message to all'
                      style='secondary'
                      className='mt-4'
                      disabled={isLoading || updatingCart}
                      onClick={() => onApplyMessageToAll(item.properties._card_message)}
                    />
                  }
                </div>
              }
      
            </div>
          }
            
    
          </form>
          <ExtendedCartMultiAddressInfo
                item={item}
                customer={customer}
                multiAddress={multiAddress}
                translations={translations}
                onAddressChange={(formObject) => onMultiAddressChange(formObject)}

          /> 
      </ExtendedCollapsible>

      </div>
    </>
  )
}