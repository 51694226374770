import React, { useState } from 'react';
import clsx from "clsx";

import ExtendedSelectField from '../../../elements/ExtendedSelectField/ExtendedSelectField';
import ExtendedCartAddressForm from "./ExtendedCartAddressForm";


const IconMarker = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="31" viewBox="0 0 7 31" fill="none">
    <path d="M1.35625 20.5205L0.30625 0H6.78125L5.6875 20.5205H1.35625ZM3.54375 31C2.52292 31 1.67708 30.6638 1.00625 29.9915C0.335417 29.3192 0 28.5153 0 27.5799C0 26.6445 0.335417 25.8553 1.00625 25.2122C1.67708 24.5398 2.52292 24.2037 3.54375 24.2037C4.56458 24.2037 5.39583 24.5398 6.0375 25.2122C6.67917 25.8553 7 26.6445 7 27.5799C7 28.5153 6.67917 29.3192 6.0375 29.9915C5.39583 30.6638 4.56458 31 3.54375 31Z" fill="black"/>
  </svg>
)

function addressMapper (address) {
  const { id, name, address1, address2, city, province, country, zip } = address;

  return {
    ...address,
    value: id,
    label: (`${name} - ${[address1, address2, city, province, zip, country].filter(Boolean).join(', ')}`)
  }
}

export default function ExtendedCartAddressInfo({
  customer,
  corporateOrderUrl,
  translations,
  onAddressChange,
  error,
  errorMessage,
  selectedShippingType,
  setSelectedShippingType,
  shippingTypes
}) {

  const addressTypes = [translations.existing_address, translations.new_address]
  const [selectedAddressType, setSelectedAddressType] = useState(addressTypes[1]);

  const mappedAddresses = customer
    ? customer.addresses.map(addressMapper)
    : []

  const [addresses, setAddresses] = useState(mappedAddresses);
  const [selectedAddress, setSelectedAddress] = useState(null);

  function handleShippingTypeChange (type) {
    if (type ===  translations.corporate_ordering) {
      return window.open(corporateOrderUrl, '_blank');

    } else {
      return setSelectedShippingType(type);
    }
  }

  function handleNewAddress (address) {
    const mappedAddress = addressMapper({
      id: null, 
      first_name: address['address[first_name]'],
      last_name: address['address[last_name]'],
      name: `${address['address[first_name]']} ${address['address[last_name]']}`.trim(),
      company: address['address[company]'], 
      address1: address['address[address1]'], 
      address2: address['address[address2]'], 
      city: address['address[city]'], 
      province: address['address[province]'], 
      country: address['address[country]'],
      zip: address['address[zip]']
    });

    const addressesCopy = addresses.concat([mappedAddress]);
    setAddresses(addressesCopy)
  }

  return (
    <>
      
      { error && 
        ( 
          <div className="extended-cart__error-container"> 
            <div className='extended-cart__error-container-icon'> 
              <IconMarker/>
            </div>
            <div className='flex flex-col'> 
              <div className='extended-cart__error-container-title'>  Make sure to check all information </div>
              <div className='extended-cart__error-container-subtitle'> {errorMessage} </div>
            </div>
          </div>
        )
      }
      <div>
        <ul className='hidden lg:flex items-center border-b border-black'>
          {shippingTypes.map((type) => {
            return (
              <li
                key={type.label}
                className={clsx('flex-1 text-center p-4 border border-b-0 hover:border-black hover:text-black cursor-pointer', {
                  'border-black text-black': selectedShippingType === type.value,
                  'border-mb-neutral-500 text-mb-neutral-500': selectedShippingType !== type.value,
                })}
                onClick={() => handleShippingTypeChange(type.value)}
              >
                {type.label}
              </li>
            )
          })}
        </ul>

        <ExtendedSelectField
          options={shippingTypes}
          value={selectedShippingType}
          className='lg:hidden'
          onChange={(option) => handleShippingTypeChange(option.value)}
        />
      </div>

      {selectedShippingType === shippingTypes[0].value &&
        <div className='p-4 flex flex-col gap-6 border border-mb-neutral-500 rounded-lg shadow-small'>
          <h4 className='font-medium'>
            {translations.shipping_address}
          </h4>

          {customer &&
            <ul className='flex gap-4'>
              {addressTypes.map((type) => {
                return (
                  <li
                    key={type}
                    className={clsx('text-sm cursor-pointer font-medium lg:p-1', {
                      'border-b border-black': selectedAddressType === type,
                    })}
                    onClick={() => setSelectedAddressType(type)}
                  >
                    {type}
                  </li>
                )
              })}
            </ul>
          }

          {selectedAddressType === addressTypes[0] &&
            <ExtendedSelectField
              label='Existing Addresses'
              options={addresses}
              placeholder={addresses.length ? 'Select existing address' : 'No existing address'}
              onChange={(option) => setSelectedAddress(option)}
            />
          }

          <ExtendedCartAddressForm
            customer={customer}
            existingAddress={selectedAddress}
            translations={translations}
            onAddressChange={(formObject) => onAddressChange(formObject)}
            onSubmit={(address) => handleNewAddress(address)}
          />
        </div>
      }
    </>
  )
}